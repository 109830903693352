import "./App.css";
import UserCount from "./UserCount";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./logo1.png" className="App-logo" alt="logo" />
        <h1>Active users</h1>
        <UserCount />
      </header>
    </div>
  );
}

export default App;
