import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSpring, animated } from "@react-spring/web"; // Import react-spring for animation
import "./UserCount.css"; // Import CSS styles

const UserCount = () => {
  const [userCount, setUserCount] = useState(0);
  const [timer, setTimer] = useState(60); // Menambahkan state untuk timer countdown

  // Animasi untuk bergulir ke angka baru
  const props = useSpring({
    from: { number: 0 },
    to: { number: userCount },
    config: { duration: 800 },
  });

  // Function to fetch user count from the API
  const fetchUserCount = () => {
    axios
      .get("https://dev.kiraproject.id/all-user")
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setUserCount(response.data.data.length);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching user count:", error);
      });
  };

  useEffect(() => {
    // Fetch initial count
    fetchUserCount();

    // Mengatur interval untuk hit API setiap 60 detik
    const interval = setInterval(() => {
      fetchUserCount();
      setTimer(60); // Reset timer setelah hit API
    }, 60000); // Set polling interval to 60 seconds (60000ms)

    // Mengatur countdown timer
    const countdown = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000); // Mengurangi 1 detik setiap 1 detik

    // Clean up intervals on component unmount
    return () => {
      clearInterval(interval);
      clearInterval(countdown);
    };
  }, []);

  // Function to format large numbers (like YouTube subscribers count)
  const formatNumber = (num) => {
    return new Intl.NumberFormat("en-US").format(num); // Format number as 000.000.000
  };

  return (
    <div className="user-count-container">
      <div className="count-circle">
        {/* Use animated span for smooth count animation */}
        <animated.span className="count-number">
          {props.number.to((n) => formatNumber(Math.floor(n)))}
        </animated.span>
      </div>
      <div className="timer-container">
        {/* Menampilkan hitungan mundur di layar */}
        <p className="">Udate Dalam: {timer} detik</p>
      </div>
    </div>
  );
};

export default UserCount;
